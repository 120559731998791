import React, { useCallback, useEffect, useState, useContext } from 'react'
import { AppContext } from '../../Contexts/AppContext';
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import { tryParseJSON } from '../../Common/commonFunction';
import { FAQsData } from '../../Common/constant';
import SEO from '../../Common/Components/SEO/SEO';
import './FAQs.css';

const FAQs = () => {
    const { FAQsContent } = useContext(AppContext);
    const [faqData, setFaqData] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [activeAccordions, setActiveAccordions] = useState({});

    useEffect(() => {
        const updateAccordions = (content) => {
            setActiveTab(content[0]?.Id);
            setActiveAccordions(
                content.reduce((acc, tab) => {
                    const parsedQuestions = tryParseJSON(tab.Questions); // Parse the questions string
                    if (parsedQuestions) {
                        acc[tab.Id] = parsedQuestions.map((_, idx) => idx === 0);
                    } else {
                        acc[tab.Id] = []; // Handle case where parsing fails
                    }
                    return acc;
                }, {})
            );
            setFaqData(content);
        };

        if (FAQsContent) {
            updateAccordions(FAQsContent);
        } else {
            updateAccordions(FAQsData);
        }
    }, [FAQsContent]);

    const handleTabClick = useCallback((tabId) => setActiveTab(tabId), []);

    const handleAccordionToggle = (tabId, idx) => {
        setActiveAccordions((prev) => ({
            ...prev,
            [tabId]: prev[tabId].map((isOpen, i) => (i === idx ? !isOpen : false)),
        }));
    };

    return (
        <>
            <SEO
                title="FAQs | SharePoint Development Experts - NextAspect Technologies"
                description="Find answers to common questions about NextAspect Technologies' SharePoint development services. Learn how we deliver custom solutions to enhance collaboration and productivity."
                keywords="SharePoint development, FAQs, NextAspect Technologies, custom SharePoint solutions, SharePoint services"
                canonical="https://nextaspecttech.com/faqs"
                robots="index, follow"
            />
            <Breadcrumb Title="FAQ'S" SubTitle="Frequently Asked Questions" Description="" />
            <div className="faq-page sec-mar">
                <div className="container">
                    <div className="row gy-5 justify-content-lg-between justify-content-center">
                        {/* Tab Navigation */}
                        <div className="col-lg-4">
                            <div className="faq-items" data-aos="fade-up">
                                <ul className="nav nav-tabs" role="tablist">
                                    {faqData.map((tab, index) => (
                                        <li className="nav-item" role="presentation" key={tab.Id} data-aos="fade-up" data-aos-delay={index * 100}>
                                            <button
                                                className={`nav-link ${activeTab === tab.Id ? "active" : ""}`}
                                                onClick={() => handleTabClick(tab.Id)}
                                                type="button"
                                                role="tab"
                                                aria-controls={tab.Id}
                                                aria-selected={activeTab === tab.Id}
                                            >
                                                {tab.Label}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/* Tab Content */}
                        <div className="col-lg-8">
                            <div className="tab-content">
                                {faqData.map((tab, index) => (
                                    <div
                                        className={`tab-pane fade ${activeTab === tab.Id ? "show active" : ""}`}
                                        id={tab.Id}
                                        role="tabpanel"
                                        key={tab.Id}
                                    >
                                        <div className="accordion" id={`accordion-${tab.Id}`}>
                                            {tryParseJSON(tab.Questions)?.map((q, idx) => (
                                                <div className="accordion-item" key={idx} data-aos="fade-up">
                                                    <h5 className="accordion-header">
                                                        <button
                                                            className={`accordion-button ${activeAccordions[tab.Id][idx] ? "" : "collapsed"}`}
                                                            type="button"
                                                            onClick={() => handleAccordionToggle(tab.Id, idx)}
                                                            aria-expanded={activeAccordions[tab.Id][idx]}
                                                        >
                                                            {q.question}
                                                        </button>
                                                    </h5>
                                                    <div
                                                        className={`accordion-collapse collapse ${activeAccordions[tab.Id][idx] ? "show" : ""
                                                            }`}
                                                    >
                                                        <div className="accordion-body" dangerouslySetInnerHTML={{ __html: q.answer }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FAQs;