export const toSlug = (label) => {
    return label
        .toLowerCase()              // Convert to lowercase
        .replace(/[\/\s]+/g, '-')   // Replace slashes and spaces with hyphens
        .replace(/[^\w\-]+/g, '')   // Remove non-word characters
        .replace(/\-\-+/g, '-');    // Replace multiple hyphens with a single hyphen
};

export const tryParseJSON = (jsonString) => {
    try {
        const parsed = JSON.parse(jsonString);
        if (parsed && typeof parsed === 'object') {
            return parsed;
        }
    } catch (e) {
        return null;
    }
    return null;
};

export const openWhatsApp = () => {
    const phoneNumber = "9727628076"; // replace with the phone number
    const message = "Hello, I would like to inquire about your services."; // customize the message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    // Open WhatsApp in a new tab
    window.open(url, "_blank");
};

export const extractToValues = (data) => {
    let result = [];
    data?.forEach(obj => {
        if (obj.items) {
            result = result.concat(extractToValues(obj.items));
        }
        if (obj.submenus) {
            result = result.concat(extractToValues(obj.submenus));
        }
        if (obj.title && obj.to) {
            result.push({ title: obj.title, to: obj.to });
        }
    });
    return result;
}
