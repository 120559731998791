import React from 'react'
import MagneticHover from '../../Components/MagneticHover/MagneticHover'

const Feature = () => {
    return (
        <div className='mt-8'>
            <section className="nk-contact-section section-space-banner-top section-space-bottom pb-md-7 pt-md-7 has-mask">
                <div className="container">
                    <div className="row g-gs align-items-center justify-content-between mb-4">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 pe-lg-5">
                                <div className="nk-block-head-content m-0">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div className="media media-md media-circle media-middle text-bg-danger-soft">
                                            <em className="icon ni ni-ticket-alt"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-danger">
                                                TICKETING SYSTEM</div>
                                        </div>
                                    </div>
                                    <h3 className="mb-2 mb-md-5">Analyze Ticketing System in detail</h3>
                                    <p className="lead" id="Reports">UHelp is a powerful helpdesk software that streamlines
                                        customer support operations, automates ticket management, and enhances
                                        collaboration among agents. It offers a wide range of features to improve
                                        customer experience and agent efficiency. </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-banner-img mt-6 mt-lg-0"
                                data-aos="fade-in" data-aos-delay="300">
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/screen01.png')} alt="banner-cover" className="rounded-1" />
                                </MagneticHover>
                            </div>
                        </div> */}

                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-feature-overview-img border-round-2xl" data-aos-delay="100">
                                {/* <img src={require('../../Assets/images/ProductDetails/cover-25.png')} alt="cover-base" className="base" /> */}

                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/screen01.png')} alt="cover-children"
                                        className=" children-2 animate animate-shakeY animate-duration-12" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-contact-section section-space-banner-top section-space-bottom pb-md-7 pt-md-7 has-mask">
                <div className="container">
                    <div className="row g-gs align-items-center justify-content-between mb-4 md:flex-row-reverse">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 pe-lg-5">
                                <div className="nk-block-head-content m-0">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div className="media media-md media-circle media-middle text-bg-danger-soft">
                                            <em className="icon ni ni-ticket-alt"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-danger">
                                                TICKETING SYSTEM</div>
                                        </div>
                                    </div>
                                    <h3 className="mb-2 mb-md-5">Analyze Ticketing System in detail</h3>
                                    <p className="lead" id="Reports">UHelp is a powerful helpdesk software that streamlines
                                        customer support operations, automates ticket management, and enhances
                                        collaboration among agents. It offers a wide range of features to improve
                                        customer experience and agent efficiency. </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-banner-img mt-6 mt-lg-0"
                                data-aos="fade-in" data-aos-delay="300">
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/screen01.png')} alt="banner-cover" className="rounded-1" />
                                </MagneticHover>
                            </div>
                        </div> */}

                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-feature-overview-img border-round-2xl" data-aos-delay="100">
                                {/* <img src={require('../../Assets/images/ProductDetails/cover-25.png')} alt="cover-base" className="base" /> */}

                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/cover-reports.png')} alt="cover-children"
                                        className=" children-2 animate animate-shakeY animate-duration-12" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-contact-section section-space-banner-top section-space-bottom pb-md-7 pt-md-7 has-mask">
                <div className="container">
                    <div className="row g-gs align-items-center justify-content-between mb-4">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 pe-lg-5">
                                <div className="nk-block-head-content m-0">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div className="media media-md media-circle media-middle text-bg-danger-soft">
                                            <em className="icon ni ni-ticket-alt"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-danger">
                                                TICKETING SYSTEM</div>
                                        </div>
                                    </div>
                                    <h3 className="mb-2 mb-md-5">Analyze Ticketing System in detail</h3>
                                    <p className="lead" id="Reports">UHelp is a powerful helpdesk software that streamlines
                                        customer support operations, automates ticket management, and enhances
                                        collaboration among agents. It offers a wide range of features to improve
                                        customer experience and agent efficiency. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-banner-img mt-6 mt-lg-0"
                                data-aos="fade-in" data-aos-delay="300">
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/screen01.png')} alt="banner-cover" className="rounded-1" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-contact-section section-space-banner-top section-space-bottom pb-md-7 pt-md-7 has-mask">
                <div className="container">
                    <div className="row g-gs align-items-center justify-content-between mb-4 md:flex-row-reverse">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 pe-lg-5">
                                <div className="nk-block-head-content m-0">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div className="media media-md media-circle media-middle text-bg-danger-soft">
                                            <em className="icon ni ni-ticket-alt"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-danger">
                                                TICKETING SYSTEM</div>
                                        </div>
                                    </div>
                                    <h3 className="mb-2 mb-md-5">Analyze Ticketing System in detail</h3>
                                    <p className="lead" id="Reports">UHelp is a powerful helpdesk software that streamlines
                                        customer support operations, automates ticket management, and enhances
                                        collaboration among agents. It offers a wide range of features to improve
                                        customer experience and agent efficiency. </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-banner-img mt-6 mt-lg-0"
                                data-aos="fade-in" data-aos-delay="300">
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/screen01.png')} alt="banner-cover" className="rounded-1" />
                                </MagneticHover>
                            </div>
                        </div> */}

                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-feature-overview-img border-round-2xl" data-aos-delay="100">
                                {/* <img src={require('../../Assets/images/ProductDetails/cover-25.png')} alt="cover-base" className="base" /> */}

                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/cover-reports.png')} alt="cover-children"
                                        className=" children-2 animate animate-shakeY animate-duration-12" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-feature-overview-section section-space has-mask">
                <div className="nk-mask blur-3"></div>
                <div className="container">
                    <div className="row align-items-center justify-content-between gy-6">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 pe-lg-5">
                                <div className="nk-block-head-content">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div
                                            className="media media-md media-circle media-middle text-bg-layout-primary-soft">
                                            <em className="icon ni ni-emails"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-layout-primary">Email To Ticket</div>
                                        </div>
                                    </div>

                                    <h3 className="mb-2 mb-md-5 ">Email To Ticket</h3>

                                    <ul className="nk-list-dot">
                                        <li className="nk-list-dot-item pb-lg-5">
                                            <p className="lead">UHelp Support Helpdesk software offers an Email-to-Ticket
                                                feature that converts customer emails into actionable support tickets.
                                                This eliminates the need for manual ticket creation and ensures no
                                                customer inquiry goes unnoticed.</p>
                                        </li>
                                        <li className="nk-list-dot-item pb-lg-5">
                                            <p className="lead" id="ticketdraft">The feature streamlines communication
                                                between customers and the support team, enhancing response times and
                                                reducing missed messages. It centralizes all support interactions,
                                                resulting in improved customer satisfaction and more organized support
                                                processes. Customers can continue conversations through email without
                                                visiting the helpdesk software.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-feature-overview-img bg-cyan-800 border-round-2xl" data-aos-delay="100">
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/cover-reports.png')} alt="cover-children"
                                        className=" children-2 animate animate-shakeY animate-duration-12" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="nk-feature-overview-section section-space mb-7">
                <div className="container">
                    <div className="row align-items-center justify-content-between gy-6 flex-row-reverse">
                        <div className="col-lg-8 order-lg-0 order-2">
                            <div className="nk-block-head md m-0 ps-lg-6">
                                <div className="nk-block-head-content m-0">
                                    <div className="flex align-items-center mb-3 mb-lg-5">
                                        <div className="media media-md media-circle media-middle text-bg-danger-soft">
                                            <em className="icon ni ni-bar-c"></em>
                                        </div>
                                        <div className="ml-3">
                                            <div className="overline-title small text-danger">Reports</div>
                                        </div>
                                    </div>
                                    <h3 className="mb-2 mb-md-5">Applications Analytics Reports</h3>
                                    <ul className="nk-list-dot">
                                        <li className="nk-list-dot-item pb-lg-5 flex">
                                            <p className="lead">Uhelp software provides various types of reports such as
                                                Active/Inactive Employees & Customers, ticket volume based on status,
                                                ticket priority, knowledge base, and agent performance reports, enabling
                                                users to track key metrics and gain insights into the performance of
                                                their support team. </p>
                                        </li>
                                        <li className="nk-list-dot-item pb-lg-5 flex">
                                            <p className="lead" id="emailtoticket">Analyzing these reports helps support
                                                teams optimize their processes, improve customer experiences, and
                                                achieve their goals by identifying areas    for improvement.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="nk-feature-overview-img bg-primary border-round-2xl" data-aos-delay="100">
                                {/* <img src={require('../../Assets/images/ProductDetails/cover-25.png')} alt="cover-base" className="base" /> */}
                                <MagneticHover>
                                    <img src={require('../../Assets/images/ProductDetails/cover-reports.png')} alt="cover-children"
                                        className=" children-2 animate animate-shakeY animate-duration-12" />
                                </MagneticHover>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Feature;