import React, { useContext, useEffect, useState } from 'react';
import { InputText, InputTextarea, Dropdown, InputNumber } from 'primereact';
import ThankyouDialog from '../../Components/ThankyouDialog/ThankyouDialog';
import { Lists } from '../../Common/constant';
import { addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Import Firebase Storage functions
import { AppContext } from '../../Contexts/AppContext';
import { Contact } from '../../Models/Contact';
import { db } from '../../Config/firebase';
import axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import SEO from '../../Common/Components/SEO/SEO';
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom';
import { openWhatsApp } from '../../Common/commonFunction';
import './ContactUs.css';

const ContactUs = () => {
    const { setThankyouDialog } = useContext(AppContext);
    const initialState = new Contact();
    const [state, setState] = useState(initialState);
    const { FirstName, LastName, Company, Email, Country, Product, Message, PhoneNumber } = state;
    const [submitted, setSubmitted] = useState(false);
    const [countries, setCountries] = useState([]);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [file, setFile] = useState(null); // State to handle file upload
    const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
    const [attachmentUrl, setAttachmentUrl] = useState(''); // Store file URL

    const products = [
        { name: "FAQ" },
        { name: "HelpDesk Management System" },
        { name: "Asset Management System" },
        { name: "Expense Management System" },
        { name: "Hospital Management System" },
    ];

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countryList = response.data
                    .map(country => ({
                        name: country.name.common,
                        code: country.cca2,
                        flag: country.flags.svg
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));
                setCountries(countryList);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);


    // Function to handle reCAPTCHA change
    // const handleRecaptchaChange = (token) => {
    //     setRecaptchaToken(token);
    // };

    const countryOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} src={option.flag} className="flag" style={{ width: '20px', marginRight: '10px' }} />
                <span>{option.name}</span>
            </div>
        );
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]); // Save the selected file
    };

    const uploadFileToFirebase = async () => {
        if (!file) return null;
        const storage = getStorage(); // Get Firebase storage reference
        const storageRef = ref(storage, `${Lists.CONTACT_ATTACHMENTS}/${file.name}`); // Create a reference in storage
        const uploadTask = uploadBytesResumable(storageRef, file);

        return new Promise((resolve, reject) => {
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    // Track upload progress
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    console.error('Upload failed:', error);
                    reject(error);
                },
                async () => {
                    // On successful upload, get the download URL
                    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                    setAttachmentUrl(downloadUrl); // Store the file URL
                    resolve(downloadUrl);
                }
            );
        });
    };
    const onSubmit = async (event) => {
        event.preventDefault();
        // Check if reCAPTCHA is ready
        if (!executeRecaptcha) return;
        try {
            // Call reCAPTCHA v3 and get the token
            // const recaptchaToken = await executeRecaptcha('contact_form');
            // console.log("recaptchaToken>>>>>>>>", recaptchaToken)

            if (!FirstName?.trim().length || !LastName?.trim().length || !Email?.trim().length || !Country || !Message?.trim().length) {
                setSubmitted(true);
            } else {
                // Upload the file to Firebase and get the file URL
                const fileUrl = await uploadFileToFirebase();

                const submitData = {
                    ...state,
                    Country: state.Country?.name,
                    AttachmentUrl: fileUrl, // Add the file URL to the submitData object
                    // Product: state.Product?.name,
                };
                await addDoc(collection(db, Lists.CONTACT_US_FORM), submitData);
                // Replace these values with your actual Service ID, Template ID, and User ID from EmailJS
                emailjs
                    .send('service_1be7m8p', 'template_8a0zppe', submitData, '62z12nqWZGFxiPk0Z')
                    .then(
                        (response) => {
                            console.log('Email sent successfully:', response);
                            alert('Email sent successfully!');
                        },
                        (error) => {
                            console.error('Error sending email:', error);
                            alert('Failed to send email.');
                        }
                    );
                setState(initialState);
                setThankyouDialog(true);
                setSubmitted(false);
            }
        } catch (error) {
            console.error('Error adding document: ', error);
            alert('There was an error sending your message. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <SEO
                title="Contact Us | NextAspect Technologies - Software Development Services"
                description="Connect with us to learn how we can empower your business to thrive! Reach out for expert digital solutions, answers to your inquiries, and potential collaborations."
                keywords="SharePoint Consulting Services India, React Application Development, SharePoint Framework (SPFx), Modern SharePoint Solutions, React UI Development,Enterprise SharePoint Solutions,React & SharePoint Customizations"
                canonical="https://nextaspecttech.com/contact-us"
            />
            <div className="contact-page-wrap sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-content">
                                <span>CONTACT WITH US</span>
                                <h2>LET’S WORK TOGETHER?</h2>
                                <p>Need assistance, have a question, or want to talk about your IT needs? Our experts are here to help. Contact us today, and we'll respond quickly. Let’s work together to elevate your technology.</p>
                                <div className="informations">
                                    <div className="single-info">
                                        <div className="icon">
                                            <i className="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div className="info">
                                            <p>India, Gujarat  <br /> Rajkot - 360004</p>
                                        </div>
                                    </div>
                                    {/* <div className="single-info">
                                        <div className="icon">
                                            <i className="fas fa-phone-alt"></i>
                                        </div>
                                        <div className="info">
                                            <Link to="tel:+919727628076">+91 972 762 8076</Link>
                                        </div>
                                    </div> */}
                                    <div className="single-info">
                                        <div className="icon">
                                            <i className="far fa-envelope"></i>
                                        </div>
                                        <div className="info">
                                            <Link to="mailto:nextaspecttech@gmail.com">nextaspecttech@gmail.com</Link>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="follow-area">
                                    <h5 className="blog-widget-title">Follow Us</h5>
                                    <p className="para">Follow us on Social Network</p>
                                    <div className="blog-widget-body">
                                        <ul className="follow-list d-flex flex-row align-items-start gap-4">
                                            <li><Link to="https://www.linkedin.com/company/nextaspect-technologies" target='_blank'><i className="pi pi-linkedin"></i></Link></li>
                                            <li><Link to="https://www.youtube.com/@NextAspect_Technologies" target='_blank'><i className="pi pi-youtube"></i></Link></li>
                                            <li><Link to="https://www.instagram.com/nextaspect_technologies/" target='_blank'><i className="pi pi-instagram"></i></Link></li>
                                            <li><Link to="#" onClick={openWhatsApp}><i className="pi pi-whatsapp"></i></Link></li>
                                        </ul>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-wrap">
                                <div className="form-tltle">
                                    <h5>Let's help you!</h5>
                                </div>
                                <div className="contact-form">
                                    <form onSubmit={onSubmit}>
                                        <div className="grid">
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>First name</label><span className='p-error'> *</span>
                                                    <InputText
                                                        name="FirstName"
                                                        value={FirstName}
                                                        onChange={handleInputChange}
                                                        className={`p-inputtext-sm ${submitted && !FirstName?.trim().length ? 'p-invalid' : ''}`}
                                                    />
                                                    {submitted && !FirstName?.trim().length && <small className="p-error">First Name is required</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>Last name</label><span className='p-error'> *</span>
                                                    <InputText
                                                        name="LastName"
                                                        value={LastName}
                                                        onChange={handleInputChange}
                                                        className={`p-inputtext-sm ${submitted && !LastName?.trim().length ? 'p-invalid' : ''}`}
                                                    />
                                                    {submitted && !LastName?.trim().length && <small className="p-error">Last Name is required</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>Company/Organization</label><span className='p-error'> *</span>
                                                    <InputText
                                                        name="Company"
                                                        value={Company}
                                                        onChange={handleInputChange}
                                                        className={`p-inputtext-sm ${submitted && !Company?.trim().length ? 'p-invalid' : ''}`}
                                                    />
                                                    {submitted && !Company?.trim().length && <small className="p-error">Company Name is required</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>Phone</label>
                                                    <InputNumber
                                                        className="w-full"
                                                        name="PhoneNumber"
                                                        value={PhoneNumber}
                                                        onValueChange={handleInputChange}
                                                        useGrouping={false}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>Email</label><span className='p-error'> *</span>
                                                    <InputText
                                                        name="Email"
                                                        value={Email}
                                                        onChange={handleInputChange}
                                                        className={`p-inputtext-sm ${submitted && !Email?.trim().length ? 'p-invalid' : ''}`}
                                                    />
                                                    {submitted && !Email?.trim().length && <small className="p-error">Email Address is required</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="form-inner">
                                                    <label>Select a Country</label><span className='p-error'> *</span>
                                                    <Dropdown
                                                        filter
                                                        name='Country'
                                                        value={Country}
                                                        onChange={handleInputChange}
                                                        options={countries}
                                                        optionLabel="name"
                                                        className={`w-full ${submitted && !Country ? 'p-invalid' : ''}`}
                                                        itemTemplate={countryOptionTemplate}
                                                    />
                                                    {submitted && !Country && <small className="p-error">Country is required</small>}
                                                </div>
                                            </div>
                                            {/* <div className="col-12">
                                                <div className="form-inner">
                                                    <label>Select a Product</label>
                                                    <Dropdown
                                                        name='Product'
                                                        value={Product}
                                                        onChange={handleInputChange}
                                                        options={products}
                                                        optionLabel="name"
                                                        placeholder="Select a Product"
                                                        className='w-full p-inputtext-sm'
                                                    />
                                                    {submitted && !Product && <small className="p-error">Product is required</small>}
                                                </div>
                                            </div> */}
                                            <div className="col-12 mb-2">
                                                <div className="form-inner">
                                                    <label>Message</label><span className='p-error'> *</span>
                                                    <InputTextarea
                                                        name="Message"
                                                        value={Message}
                                                        onChange={handleInputChange}
                                                        className={`p-inputtext-sm ${submitted && !Message?.trim().length ? 'p-invalid' : ''}`}
                                                        placeholder="Brief about the project"
                                                    />
                                                    {submitted && !Message?.trim().length && <small className="p-error">Message is required</small>}
                                                </div>
                                            </div>
                                            <div className="col-12 mb-2">
                                                <div className="form-inner">
                                                    <label>Attach File (optional)</label>
                                                    <input type="file" onChange={handleFileChange} />
                                                    {uploadProgress > 0 && <small>Upload Progress: {uploadProgress}%</small>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-inner">
                                                    <button className="primary-btn3" type="submit">Get Free Consultation</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ThankyouDialog />
            </div>
        </>
    );
};

export default ContactUs;