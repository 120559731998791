// src/Content.js
import React, { useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact';
import { AppContext } from '../../Contexts/AppContext';
import { extractToValues, toSlug, tryParseJSON } from '../../Common/commonFunction';
import './Content.css'

const contentData = {
    "home": {
        // title: "Getting Started",
        content: `<h2 style="display: flex; align-items: center; justify-content: center; color: #ff9500;">
    <img src="https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/ProductsLogo%2Fsmart-helpDesk.png?alt=media&token=4d5f3f09-4f06-48dd-89c2-043d11b80d21"
        alt="Smart HelpDesk Logo" style="margin: 5px; width: 50px;" />
    Smart HelpDesk
</h2>
<p>
    Streamline Support Operations and Efficiently Manage and Resolve Support Requests with NextAspect Smart HelpDesk, Streamlining Collaboration and Boosting User Satisfaction.
</p>
<h2 style="border-bottom: 1px solid #ff9500; font-weight: 400;">Introduction</h2>
<h5
    style="border-left: 2px solid #ff9500; margin: 0.8em 0px; padding-left: 0.5rem">
    What is <a href="#" style="font-weight: 500;">Smart HelpDesk?</a>
</h5>
<p>Smart HelpDesk Revolutionizing Support Ticket Systems with Microsoft Framework for Seamless Collaboration.</p>
<p>
    NextAspect Smart HelpDesk is designed to transform your support processes by combining SharePoint’s strengths with
    an intuitive, user-friendly interface. Whether you’re a small business or a large enterprise, our system ensures
    streamlined ticketing and enhanced productivity.

    <br />Make your support operations smarter, faster, and more efficient with NextAspect Smart HelpDesk.
</p>
<h3
    style="border-left: 2px solid #ff9500; margin: 1.6em 0px 0.8em; font-size: 1.25em; padding-left: 0.5rem">
    How <a href="#" style="color: #ff9500; font-weight: 500;">Smart HelpDesk</a> Works?
</h3>
<p>
    This platform features three panels: Admin, Assignee, and Requester, each designed for seamless management, task
    execution, and inquiry submission.</p>
<p>
    NextAspect Smart HelpDesk streamlines ticket management by allowing Requesters to submit inquiries through a simple
    form, categorizing and prioritizing issues for better organization. Tickets are automatically routed or manually
    assigned to Assignees, who track, update, and resolve them efficiently. Real-time updates ensure that Requesters can
    monitor progress and communicate seamlessly with Assignees. The integration with Microsoft Power Automate enhances
    productivity by automating workflows, such as ticket routing and notifications. A centralized dashboard provides
    comprehensive insights through reports, helping organizations track performance, identify bottlenecks, and
    continuously improve their support processes.</p>
<p>
    <a href="#" style="color: #ff9500; font-weight: 500;">Admin</a>
</p>
<p>
    Manage departments, categories, subcategories, user roles, and workflows, ensuring seamless system
    operations and effective support.</p>
<p>
    <a href="#" style="color: #ff9500; font-weight: 500;">Assignee</a>
</p>
<p>
    Streamline task management with tools to update statuses, add comments, and resolve tickets efficiently.</p>
<p>
    <a href="#" style="color: #ff9500; font-weight: 500;">Requester</a>
</p>
<p style="margin-bottom: 0px; font-size: 16px;">
    Submit tickets effortlessly, track progress, and receive real-time updates to stay informed.<br /></p>
<br />`
    },
    "getting-started": {
        // title: "Getting Started",
        content: `<h2 style="border-bottom: 1px solid #ff9500; font-weight: 400; font-size: 1.25rem; padding-bottom: 0.5rem;">Getting Started
    with Smart HelpDesk
</h2>
<p>
    Welcome to Smart HelpDesk, your comprehensive support ticket management solution built on the robust Microsoft
    Framework. This guide will help you quickly set up and start using Smart HelpDesk to streamline your organization's
    support process.
</p>
<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem;">
    <a href="#" style="color: #ff9500; font-weight: 400;">System Requirements</a>
</h3>
<p>Ensure your system meets the following requirements:</p>
<ul>
    <li>
        <span style="font-weight: 600;">Microsoft SharePoint Environment</span>: SharePoint Online or SharePoint
        On-Premises.
    </li>
    <li>
        <span style="font-weight: 600;">Browser Compatibility</span>: Modern browsers like Chrome, Edge, or Firefox.
    </li>
    <li>
        <span style="font-weight: 600;">User Access</span>: Ensure appropriate permissions.
    </li>
</ul>
<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem;">
    <a href="#" style="color: #ff9500; font-weight: 400;">Installation</a>
</h3>
<p>Follow these steps to install Smart HelpDesk:</p>
<ol>
    <li>Download the installation package.</li>
    <li>Deploy the Smart HelpDesk solution to your SharePoint environment.</li>
    <li>Activate the solution for the required sites or subsites.</li>
</ol>
<p>Refer to the
    <span style="font-weight: 600;">Installation</span> section for detailed instructions.
</p>
<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem;">
    <a href="#" style="color: #ff9500; font-weight: 400;">Configuring the Platform</a>
</h3>
<ol>
    <li>
        <span style="font-weight: 600;">Set Up Admin Panel</span>: Configure departments, categories, and user roles.
    </li>
    <li>
        <span style="font-weight: 600;">Integrate Email Notifications</span>: Enable email configurations for automatic
        ticket updates.
    </li>
    <li>
        <span style="font-weight: 600;">Workflow Automation</span>: Use Microsoft Power Automate to customize workflows.
    </li>
</ol>
<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem;">
    <a href="#" style="color: #ff9500; font-weight: 400;">Start Using Smart HelpDesk</a>
</h3>
<p>Once setup is complete, users can:</p>
<ul>
    <li>Submit tickets through the intuitive interface.</li>
    <li>Assign and resolve tickets seamlessly.</li>
    <li>Monitor performance and generate reports.</li>
</ul>
<p>Explore the detailed guides in the navigation menu for further assistance!<br /></p>

<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem;">
    <a href="#" style="color: #ff9500; font-weight: 400;">Getting Started Video</a>
</h3>

<div style="display: flex; justify-content: center;">
    <iframe src="https://www.youtube.com/embed/Fwuxa5FGwSM" frameborder="0" allowfullscreen=""
        style="box-sizing: inherit; max-width: 100%; width: 688px; height: 22.172vw;"></iframe><br />
                  </div>`
    },
    "installation": {
        title: "Installation",
        content: `<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Dashboard</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#dashboard" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can show</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Total Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Active Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Recent Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">My Assigned Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Resolved Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Closed Tickets</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Monthly Summery</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0.5em; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Summery by Categories</strong></p>
   </li>
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <p style="box-sizing: inherit; margin-top: 0.5em; margin-bottom: 0.5em;">
         <strong style="box-sizing: inherit;">Active Tickets List</strong></p>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/Zaidesk-Dashboard.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">User</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#user" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view all customers and agents from it add, edit and convert customer to agent and agent to customer.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-2.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;<img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-3.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/></p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Tag</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#tag" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view all tags and add tag , delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-4.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Category</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#category" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view all category and add category , delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-5.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">My Subscription</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#my_subscription" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can show all Subscription plan in this panel.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-1.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">All Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#all_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view all ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-6.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Recent Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#recent_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view new recent ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-7.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Active Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#active_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view active ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-8.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Resolved Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#resolved_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view resolve ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-9.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Closed Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#closed_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view closed ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-10.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">On-hold Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#on-hold_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view On-Hole ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-11.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Assigned Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#assigned_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view assigned ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-44.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Suspended Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#suspended_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view Suspended ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-12.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Trashed Tickets</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#trashed_tickets" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view Trashed ticket and delete and edit .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-13.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Envato</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#envato" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Envato Configuration</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#envato_configuration" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view Envato Configuration and action on this configuration .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-2.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Envato License Verification</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#envato_license_verification" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can view Envato Configuration and action on this configuration .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-3.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Settings</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#settings" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">General Settings</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#general_settings" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Application Setting</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#application_setting" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can show Application Settings and update name, email, number, location and timezone .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-4.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h4 style="box-sizing: inherit; font-weight: 700; letter-spacing: -0.01em; margin: 1em 0px;">
         <strong style="box-sizing: inherit;">Theme Setting</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#theme_setting" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h4>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can show Theme Settings and update preloader, logo, fav-icon, admin logo and footer logo and login left image .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-5.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; padding: 0rem 0rem 0.5rem 0.5rem !important;">
         <strong style="box-sizing: inherit;">Domain Setup</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#domain_setup" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can create new domain from this panel. And if it has&#160;<strong style="box-sizing: inherit;">Custom Domain Support Yes</strong>&#160;in any of its price plans, then you can change the domain as you wish. .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-6.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<ul style="box-sizing: inherit; margin-bottom: 1em; margin-top: 1em; padding: 0px; margin-left: 0.625em; display: flow-root; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <li style="box-sizing: inherit; margin-bottom: 0px; margin-left: 1.25em;">
      <h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; padding: 0rem 0rem 0.5rem 0.5rem !important;">
         <strong style="box-sizing: inherit;">Support Schedule</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#support_schedule" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
   </li>
</ul>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can update Support Schedule this column and update the Schedule column.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-7.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Cms Settings</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#cms_settings" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 0.8em 0px; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Basic Cms Settings</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#basic_cms_settings" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can manage Basic Frontend Settings this setting , update logo and link.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-32.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Section Settings</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#section_settings" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can manage Frontend Section Settings update all frontend header logo.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-33.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Features</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#features" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can manage Features setting and update title , description and logo .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-34.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Faq Category</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#faq_category" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add Faq Category can add faq. Admin can edit and delete this category.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-35.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Faq</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#faq" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add Faq with Faq Category. Admin can edit and delete this faq question and answer.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-36.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Testimonial</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#testimonial" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can manage this Testimonial setting. Admin manage this user experience user name , review manege , edit delete and add.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-37.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Pages</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#pages" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can manage this Pages. Admin edit just page .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-38.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Knowledge Category</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#knowledge_category" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add Knowledge Category can add Knowledge. Admin can edit and delete this category.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-39.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h3 style="box-sizing: inherit; border-left: 2px solid #4696ec; margin: 1.6em 0px 0.8em; font-size: 1.25em; letter-spacing: -0.01em; line-height: 1.5; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff; padding: 0rem 0rem 0.5rem 0.5rem !important;">
   <strong style="box-sizing: inherit;">Knowledge</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#knowledge" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h3>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add Knowledge with Knowledge Category. Admin can edit and delete this knowledge title and description.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-40.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Dynamic Fields</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#dynamic_fields" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can add Create Dynamic Fields For Ticket Create .</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-u-8.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Contact Messages</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#contact_messages" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can show this all contact messages and edit this messages.</p>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-42.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>&#160;</p>
<h2 style="box-sizing: inherit; border-bottom: 1px solid #4696ec; margin: 1.6em 0px 0.64em; letter-spacing: -0.01em; font-size: 1.5625em; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; background-color: #ffffff;">
   <strong style="box-sizing: inherit;">Announcement</strong><a class="headerlink" href="https://zaidesk-doc.vercel.app/admin.html#announcement" title="Permanent link" style="box-sizing: inherit; cursor: pointer; -webkit-tap-highlight-color: transparent; word-break: break-word; transition: color 0.25s, opacity 125ms; display: inline-block; opacity: 0; margin-left: 0.5rem;">¶</a></h2>
<p style="box-sizing: inherit; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">Admin can make a separate announcement for the customer.</p>
<p style="box-sizing: inherit; margin-bottom: 0px; font-family: roboto, -apple-system, blinkmacsystemfont, helvetica, arial, sans-serif; font-size: 16px; background-color: #ffffff;">
   <img alt="Image" src="https://zaidesk-doc.vercel.app/images/admin-img/a-43.png" data-themekey="#" style="box-sizing: inherit; max-width: 100%; margin: 5px; width: 0px;"/>
   <br/>
</p>
                  <br/>`
    },
    "admin-dashboard": {
        // title: "Admin Dashboard",
        content: `<h2 style="border-bottom: 1px solid #ff9500; font-weight: 400; font-size: 1.25rem; padding-bottom: 0.5rem;">Admin
    Dashboard</h2>
<h5 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; padding-left: 0.5rem"><a href="#"
        style="color: #ff9500; font-weight: 400;">Overview</a>
</h5>
<p>
    The Admin Dashboard is your centralized hub for efficient ticket management and tracking of key performance
    indicators (KPIs). It enables administrators to gain actionable insights into ticket statuses, department workloads,
    priorities, and top contributors. Designed with responsiveness and real-time updates, the dashboard ensures smooth
    operations and better decision-making.
</p>
<img src="https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/ProductsLogo%2Fsmart-helpDesk.png?alt=media&token=4d5f3f09-4f06-48dd-89c2-043d11b80d21"
    alt="Smart HelpDesk Logo" style="margin: 5px;" />
<h2 style="border-bottom: 1px solid #ff9500; font-weight: 400; font-size: 1.25rem; padding-bottom: 0.5rem;">Key Dashboard Sections</h2>

<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
    <a href="#" style="color: #ff9500; font-weight: 400;">Ticket Summary</a>
</h3>

<p>The top section of the dashboard provides an at-a-glance summary of ticket counts under the following categories:</p>
<ul>
    <li>
        <span style="font-weight: 600;">All Tickets</span>: The total number of tickets in the system.
    </li>
    <li>
        <span style="font-weight: 600;">New Tickets</span>: Tickets that have been created but are yet to be
        addressed.
    </li>
    <li>
        <span style="font-weight: 600;">Active Tickets</span>: Tickets currently being worked on by agents or admins.
    </li>
    <li>
        <span style="font-weight: 600;">Closed Tickets</span>: Tickets that have been resolved and marked as closed.
    </li>
</ul>

<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
    <a href="#" style="color: #ff9500; font-weight: 400;">Self Tickets</a>
</h3>

<p>This section highlights metrics specific to the admin&#39;s tickets, ensuring accountability and personal workload
    management:</p>
<ul>
    <li>
        <span style="font-weight: 600;">My Total Tickets</span>: The total tickets are currently assigned to the
        admin.
    </li>
    <li>
        <span style="font-weight: 600;">Active Tickets</span>: Tickets are actively being worked on by the admin.
    </li>
    <li>
        <span style="font-weight: 600;">My Assigned Tickets</span>: Tickets assigned to the admin by other users.
    </li>
    <li>
        <span style="font-weight: 600;">Closed Tickets</span>: Tickets resolved and closed by the admin.<br />
    </li>
</ul>

<h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
    <a href="#" style="color: #ff9500; font-weight: 400;">Charts Section</a>
</h3>

<p>Visual charts provide deep insights into ticket distribution, performance, and trends.</p>
<h5>
    <span style="font-weight: 400;">Ticket by Department</span>
</h5>
<ul>
    <li>
        <span style="font-weight: 600;">Purpose</span>: Displays the distribution of tickets across departments (e.g.,
        Development, QA, HR).
    </li>
    <li>
        <span style="font-weight: 600;">Details</span>: Provides the percentage and count of tickets by each
        department.
    </li>
    <li>
        <span style="font-weight: 600;">Visualization</span>: A
        <span style="font-weight: 600;">pie chart</span> with color-coded segments representing each
        department.<br />
    </li>
</ul>
<h5>
    <span style="font-weight: 400;">Ticket by Priority</span>
</h5>
<ul>
    <li>
        <span style="font-weight: 600;">Purpose</span>: Categorize tickets based on urgency levels (Low, Medium, High,
        Critical).
    </li>
    <li>
        <span style="font-weight: 600;">Details</span>:
        <ul>
            <li>
                <span style="font-weight: 600;">Low Priority</span>: Tickets with minimal urgency.
            </li>
            <li>
                <span style="font-weight: 600;">Medium Priority</span>: Tickets requiring attention within
                standard timelines.
            </li>
            <li>
                <span style="font-weight: 600;">High Priority</span>: Tickets needing immediate action.
            </li>
            <li>
                <span style="font-weight: 600;">Critical Priority</span>: Tickets with the highest urgency
                level.<br />
            </li>
        </ul>
    </li>
</ul>
<h5>
    <span style="font-weight: 400;">Top Ticket Creator</span>
</h5>
<ul>
    <li>
        <span style="font-weight: 600;">Purpose</span>: Highlights users contributing the most tickets.
    </li>
    <li>
        <span style="font-weight: 600;">Details</span>:
        <ul>
            <li>Lists names of top contributors.</li>
            <li>Shows the count and percentage of tickets created by each user.</li>
        </ul>
    </li>
    <li>
        <span style="font-weight: 600;">Visualization</span>: A
        <span style="font-weight: 600;">pie chart</span> ranking top creators based on their ticket
        contributions.<br />
    </li>
</ul>

<h5>
    <span style="font-weight: 400;">Ticket History</span>
</h5>
<ul>
    <li>
        <span style="font-weight: 600;">Purpose</span>: Visualizes the creation trends of tickets over a selected
        time.<br />
    </li>
    <li>
        <span style="font-weight: 600;">Details</span>:
        <ul>
            <li>Displays the total number of tickets per month.</li>
            <li>Peaks and troughs in ticket creation highlight busy and quiet periods.</li>
            <li>Admins can filter the data by different time intervals (e.g., monthly).<br /></li>
        </ul>
    </li>
</ul>
<h5>
    <span style="font-weight: 400;">Ticket By Status</span>
</h5>
<ul>
    <li>
        <span style="font-weight: 600;">Purpose</span>: Categorize tickets based on their current status.
    </li>
    <li>
        <span style="font-weight: 600;">Details</span>:
        <ul>
            <li>Includes statuses like
                <span style="font-weight: 600;">New</span>,
                <span style="font-weight: 600;">In Progress</span>, and others.
            </li>
            <li>Allows admins to focus on the workload distribution and unresolved tickets.</li>
            <li>Provides filtering options (e.g., &quot;This Month&quot;).<br /></li>
        </ul>
    </li>
</ul>`
    },
    "tags": {
        // title: "Tags",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem">Category</h3>

    <p style="font-size: 16px; background-color: #ffffff;">
        Admin can view all category and add category , delete and edit .</p>

    <img alt="Image" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-5.png"
        data-themekey="#" style="max-width: 100%; margin: 5px; width: 60px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 1.6em 0px 0.8em; font-size: 1.25em; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 500;">Category Management Features</a>
    </h3>

    <ul>
        <li>
            <p>
                <strong>Search Categories:</strong>
            </p>
            <ul>
                <li>Use the
                    <strong>Search Bar</strong> in the top right corner to quickly locate a category by name.
                </li>
            </ul>
        </li>
        <li>
            <p>
                <strong>Edit Categories:</strong>
            </p>
            <ul>
                <li>Click the
                    <strong>pencil icon</strong> (green) in the
                    <strong>Action</strong> column to modify an existing category.
                </li>
                <li>Update the category name as needed and save changes.</li>
            </ul>
        </li>
        <li>
            <p>
                <strong>Delete Categories:</strong>
            </p>
            <ul>
                <li>Use the&#160;<span style="box-sizing: border-box; margin: 0px; padding: 0px;"><strong>Action
                            column&#39;s trash icon</strong>&#160;(red)&#160;</span>to remove a category.</li>
                <li>Confirm the deletion prompt to delete the category permanently.</li>
            </ul>
        </li>
        <li>
            <p>
                <strong>Bulk Actions:</strong>
            </p>
            <ul>
                <li>Select multiple categories using the checkboxes on the left and apply bulk actions like deletion.
                </li>
            </ul>
        </li>
        <li>
            <p>
                <strong>Export Categories:</strong>
            </p>
            <ul>
                <li>Click the
                    <strong>Export</strong> button to download a list of all categories in a CSV or Excel format.<br />
                </li>
            </ul>
        </li>
    </ul>`
    },
    "global-tickets": {
        title: "Global Tickets",
        content: "Steps to set up a custom domain..."
    },
    "trashed-tickets": {
        title: "Trashed Tickets",
        content: "Frequently Asked Questions..."
    },
    "categories": {
        // title: "Categories",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Categories</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Manage Categories section in the admin dashboard provides an organized way to
        create, update, and manage categories for tickets or inquiries. These categories streamline user requests,
        making it easier to sort and prioritize issues effectively.
    </p>

    <img alt="categories" src="https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/Products%2FSmart%20HelpDesk%2FAdmin%20Panel%2Fcategories-smart-helpdesk.png?alt=media&token=edde1fa5-ab81-4c7a-bc8a-828c7b424763"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Adding and Managing Categories</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Adding a New Category:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Enter the
                    <span style="font-weight: 600;">Category Name</span> (e.g.,
                    <em>Technical Support</em>).
                </li>
                <li>Save the new category.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Editing an Existing Category:</span>
            </p>
            <ul>
                <li>Locate the category you want to update and click the
                    <span style="font-weight: 600;">edit icon (pencil)</span> in the
                    <span style="font-weight: 600;">Action</span> column.
                </li>
                <li>Modify the
                    <span style="font-weight: 600;">Category Name</span> as needed.
                </li>
                <li>Save the changes.<br /></li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Deleting a Category:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon (trash can)</span> in the
                    <span style="font-weight: 600;">Action</span> column next to the category you want to remove.
                </li>
                <li>Confirm the deletion to finalize the action.<br /></li>
            </ul>
        </li>
    </ul>`
    },
    "sub-categories": {
        // title: "Sub Categories",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Subcategories</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Manage Subcategories section in the admin dashboard enables administrators to
        create, update, and organize subcategories under parent categories. This functionality ensures proper
        classification and streamlined navigation within the system.
    </p>

    <img alt="subcategories" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Adding and Managing Subcategories</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Add a New Subcategory:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Fill in the following fields:
                    <ul>
                        <li>
                            <span style="font-weight: 600;">Name</span> (mandatory): The subcategory&#39;s name.
                        </li>
                        <li>
                            <span style="font-weight: 600;">Parent Category</span> (dropdown): Select the appropriate
                            parent category.
                        </li>
                    </ul>
                </li>
                <li>Save the changes to add the subcategory to the list.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Edit a Subcategory:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">edit icon</span> (pencil) in the Action column.
                </li>
                <li>Update the subcategory&#39;s details, such as name or parent category.</li>
                <li>Save the updated subcategory.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Delete a Subcategory:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon</span> in the Action column.
                </li>
                <li>Confirm the deletion to finalize the action.<br /></li>
            </ul>
        </li>
    </ul>`
    },
    "departments": {
        // title: "Departments",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Departments</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Manage Departments section in the admin dashboard allows administrators to create,
        update, and organize departments. Each department can be assigned to a specific user, and tickets or tasks
        related to that department will be automatically assigned to the designated user.
    </p>

    <div class="flex justify-content-center">
        <img alt="departments"
            src="https://firebasestorage.googleapis.com/v0/b/nextaspectsite.appspot.com/o/Products%2FSmart%20HelpDesk%2FAdmin%20Panel%2Fsmart-helpdesk-department.png?alt=media&token=79034eea-af25-439d-be5b-78bd59e2a005"
            style="max-width: 70%; margin: 5px;" />
    </div>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Adding and Managing Departments</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Add a New Department:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Fill in the following fields:
                    <ul>
                        <li>
                            <span style="font-weight: 600;">Name</span> (mandatory): The department's name (e.g.,
                            IT, HR).
                        </li>
                        <li>
                            <span style="font-weight: 600;">Assigned To</span> (mandatory): Select a user from the
                            dropdown to assign to the
                            department. This user will receive tickets or tasks related to this department.
                        </li>
                    </ul>
                </li>
                <li>Save the changes to add the department to the list.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Edit a Department:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">edit icon</span> in the Action column.
                </li>
                <li>Update the department’s details, such as name or assigned user.</li>
                <li>Save the updated department.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Delete a Department:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon</span> in the Action column.
                </li>
                <li>Confirm the deletion to finalize the action.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Enable or Disable Departments:</span>
            </p>
            <ul>
                <li>Control the availability of departments by toggling their
                    <span style="font-weight: 600;">status</span>.<br />
                </li>
            </ul>
        </li>
        <li>
            <p><span style="font-weight: 600;">Assigning Tickets Based on Departments</span></p>
        </li>
        <ul>
            <li>When a ticket is created and a department is selected, it will be automatically assigned to the user
                associated with that department.</li>
            <li>Ensure that the
                <span style="font-weight: 600;">Assigned To</span> field is accurately configured for seamless ticket
                assignment.<br />
            </li>
        </ul>
                  </ul>`
    },
    "priorities": {
        // title: "Priorities",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Priorities</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Manage Priorities section in the admin dashboard allows administrators to define,
        update, and organize priorities for tasks, tickets, or other system items. Priorities help classify urgency
        levels, ensuring efficient resource allocation and timely resolution.
    </p>

    <img alt="priorities" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Adding and Managing Priorities</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Add a New Priority:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Fill in the following fields:
                    <ul>
                        <li>
                            <span style="font-weight: 600;">Name</span> (mandatory): The priority&#39;s name (e.g.,
                            High, Medium, Low).
                        </li>
                        <li>
                            <span style="font-weight: 600;">Color Code</span> (optional): Select a color to visually
                            identify the priority.
                        </li>
                    </ul>
                </li>
                <li>Save the changes to add the priority to the list.<br /></li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Edit a Priority:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">edit icon</span> in the Action column.
                </li>
                <li>Update the priority&#39;s details, such as name or color code.</li>
                <li>Save the updated priority.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Delete a Priority:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon</span> in the Action column.
                </li>
                <li>Confirm the deletion to finalize the action.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Enable or Disable Priorities:</span>
            </p>
            <ul>
                <li>Control the availability of priorities by toggling their
                    <span style="font-weight: 600;">status</span>.<br />
                </li>
            </ul>
        </li>
                  </ul>`
    },
    "statuses": {
        // title: "Statuses",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Statuses</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Manage Statuses section in the admin dashboard allows administrators to view and
        customize the appearance of ticket or task statuses by editing their associated colors. These statuses (e.g.,
        Open, In Progress, Resolved) help track the progress of tasks effectively, with color codes providing a quick
        visual reference.
    </p>

    <img alt="statuses" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Editing Status Colors</a>
    </h3>

    <ul>
        <li>Locate the desired status in the list.</li>
        <li>Click the
            <span style="font-weight: 600;">edit icon</span> in the Action column.
        </li>
        <li>A color picker or hex code input box will appear:
            <ul>
                <li>
                    <span style="font-weight: 600;">Using the Color Picker:</span> Select a color by clicking
                    directly on the color
                    palette.
                </li>
                <li>
                    <span style="font-weight: 600;">Using Hexadecimal Code:</span> Enter a hex code (e.g.,
                    <code>#FF5733</code>) in the input field.
                </li>
            </ul>
        </li>
        <li>Preview the selected color to ensure it matches your needs.</li>
        <li>Click
            <span style="font-weight: 600;">Save</span> to apply the changes.
        </li>
                  </ul>`
    },
    "automated-replies": {
        // title: "Automated Replies",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">
        Automated Replies</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p>The Automated Replies section in the admin dashboard allows administrators to manage
        pre-configured replies that are automatically sent to users under specific conditions or events. This feature
        ensures timely and consistent communication with users.
    </p>

    <img alt="automated-replies" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Managing Automated Replies</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Adding a New Reply:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Enter the
                    <span style="font-weight: 600;">name</span> of the reply and its
                    <span style="font-weight: 600;">message content</span>.
                </li>
                <li>Specify triggering conditions or rules (e.g., ticket closure, escalation notice).</li>
                <li>Toggle the status to enable or disable the reply.</li>
                <li>Save the new reply.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Editing an Existing Reply:</span>
            </p>
            <ul>
                <li>Locate the reply you wish to update and click the
                    <span style="font-weight: 600;">edit icon </span> in the
                    <span style="font-weight: 600;">Action</span> column.
                </li>
                <li>Modify the name, content, or triggering conditions.</li>
                <li>Save the changes.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Deleting a Reply:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon </span> in the
                    <span style="font-weight: 600;">Action</span> column to remove a reply.
                </li>
                <li>Confirm the deletion to finalize the action.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Enabling or Disabling Replies:</span>
            </p>
            <ul>
                <li>Toggle the
                    <span style="font-weight: 600;">Status Button</span> in the list to enable or disable a reply.<br />
                </li>
            </ul>
        </li>
                  </ul>`
    },
    "manage-roles": {
        // title: "Manage Roles",
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Manage
        Roles</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p style="font-size: 16px;">The Manage Roles section in the admin dashboard allows administrators to create, update,
        and assign roles to users. Roles determine user access levels and permissions within the system, ensuring a
        secure and organized hierarchy.
    </p>

    <img alt="manage-roles" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Adding and Managing Roles</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Add a New Role:</span>
            </p>
            <ul>
                <li>Click on the
                    <span style="font-weight: 600;">Add New</span> button.
                </li>
                <li>Fill in the
                    <span style="font-weight: 600;">Name</span> field (mandatory).
                </li>
                <li>Select a
                    <span style="font-weight: 600;">Role</span> from the dropdown menu:
                    <ul>
                        <li>
                            <span style="font-weight: 600;">Admin:</span> Full access to the system.
                        </li>
                        <li>
                            <span style="font-weight: 600;">User:</span> Limited access based on user permissions.
                        </li>
                    </ul>
                </li>
                <li>Save the changes to assign the role.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Edit a Role:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">edit icon </span> in the
                    <span style="font-weight: 600;">Action</span> column.
                </li>
                <li>Update the user&#39;s assigned roles or details.</li>
                <li>Save the updated role.</li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Delete a Role:</span>
            </p>
            <ul>
                <li>Click the
                    <span style="font-weight: 600;">delete icon </span> in the
                    <span style="font-weight: 600;">Action</span> column to remove a user or their role assignment.
                </li>
                <li>Confirm the deletion to finalize the action.<br /></li>
            </ul>
        </li>
                  </ul>`
    },
    "notifications": {
        // title: "Notifications",
        content: "How to configure email tickets..."
    },
    "dynamic-fields": {
        // title: "Dynamic Fields",
        content: "How to configure email tickets..."
    },
    "languages": {
        // title: "Languages",
        content: "How to configure email tickets..."
    },
    "app-settings": {
        // title: "App Settings",
        content: "How to configure email tickets..."
    },
    "email-templates": {
        content: `<h3 style="border-bottom: 1px solid #ff9500; font-weight: 400; padding-bottom: 0.5rem; font-size: 1.25rem;">Email
        Templates</h3>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Overview</a>
    </h3>

    <p style="font-size: 16px; background-color: #ffffff;">The Email Templates feature in the admin dashboard provides a
        way to manage and customize automated email notifications. These templates correspond to specific ticket events,
        such as ticket creation, updates, assignments, and status changes.</p>

    <img alt="email-templates" src="file:///D:/Sharepoint%20Empower/products/HTML/HelpCenter/images/admin-img/a-6.png"
        style="max-width: 100%; margin: 5px;" />

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Common Notifications</a>
    </h3>

    <p>Below are the pre-configured templates:</p>
    <ul>
        <li>
            <span style="font-weight: 600;">Update Ticket:</span> Sent when a ticket is updated.
        </li>
        <li>
            <span style="font-weight: 600;">Reassign Ticket:</span> Sent when a ticket is reassigned to another agent.
        </li>
        <li>
            <span style="font-weight: 600;">Close Ticket:</span> Sent when a ticket is closed.
        </li>
        <li>
            <span style="font-weight: 600;">On Hold Ticket:</span> Sent when a ticket's status is changed to
            &quot;On Hold.&quot;
        </li>
        <li>
            <span style="font-weight: 600;">Create Ticket:</span> Sent when a ticket is created.
        </li>
        <li>
            <span style="font-weight: 600;">Assign Ticket:</span> Sent when a ticket is assigned to an agent.
        </li>
        <li>
            <span style="font-weight: 600;">Reopen Ticket:</span> Sent when a ticket is reopened.<br />
        </li>
    </ul>

    <h3 style="border-left: 2px solid #ff9500; margin: 0.8em 0px; font-size: 1.25rem; padding-left: 0.5rem">
        <a href="#" style="color: #ff9500; font-weight: 400;">Editing a Template</a>
    </h3>

    <ul>
        <li>
            <p>
                <span style="font-weight: 600;">Access the Template:</span>
            </p>
            <ul>
                <li>Locate the template you want to edit and click the edit icon in the
                    <span style="font-weight: 600;">Action</span> column.
                </li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Customize the Content:</span>
            </p>
            <ul>
                <li>Modify the
                    <span style="font-weight: 600;">Subject</span> and
                    <span style="font-weight: 600;">Body</span> of the email.
                </li>
                <li>Use placeholders for dynamic content, e.g.
                    <code>{{ticket_id}}</code>,
                    <code>{{user_name}}</code>.
                </li>
            </ul>
        </li>
        <li>
            <p>
                <span style="font-weight: 600;">Preview and Save:</span>
            </p>
            <ul>
                <li>Preview the updated template to ensure accuracy.</li>
                <li>Click
                    <span style="font-weight: 600;">Save</span> to apply the changes.<br />
                </li>
            </ul>
        </li>
                  </ul>`
    },
    "reports": {
        title: "Reports",
        content: "How to configure email tickets..."
    },
    "user-dashboard": {
        title: "User Dashboard",
        content: "How to configure email tickets..."
    },
    "create-ticket": {
        title: "Create Ticket",
        content: "How to configure email tickets..."
    },
    "tickets": {
        title: "Tickets",
        content: "How to configure email tickets..."
    },
    "license-verification": {
        title: "License Verification",
        content: "How to configure email tickets..."
    },
    "email-ticket-configure": {
        title: "Email Ticket Configure",
        content: "How to configure email tickets..."
    },
    "upgradation": {
        title: "Upgradation",
        content: "How to configure email tickets..."
    },
    "uninstallation": {
        title: "Uninstallation",
        content: "How to configure email tickets..."
    }
};

const Content = () => {
    const { sidebarMenuItem, setSelected } = useContext(AppContext);
    const { section } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const data = contentData[section] || {};
    const id = location.pathname.split("/")[2];
    const result = sidebarMenuItem.find(ele => toSlug(ele.ProductName) === id);
    const routes = extractToValues(tryParseJSON(result?.MenuItem));

    // Find the current route index
    const currentIndex = routes.findIndex(item => item.to === section);

    // Handlers for navigation
    const handlePrevious = () => {
        if (currentIndex > 0) {
            navigate(`/help-center/${id}/${routes[currentIndex - 1]?.to}`); // Navigate to the previous route
            setSelected(routes[currentIndex - 1]?.to);
        }
    };

    const handleNext = () => {
        console.log("handleNExt", routes[currentIndex + 1]);
        if (currentIndex < routes.length - 1) {
            navigate(`/help-center/${id}/${routes[currentIndex + 1]?.to}`); // Navigate to the next route
            setSelected(routes[currentIndex + 1]?.to);
        }
    };

    // useEffect(() => {
    //    // Function to scroll to the section specified in the URL fragment
    //    const scrollToFragment = () => {
    //       const hash = window.location.hash;
    //       if (hash) {
    //          const element = document.querySelector(hash);
    //          if (element) {
    //             element.scrollIntoView({ behavior: 'smooth' });
    //          }
    //       }
    //    };

    //    // Call the scroll function on mount
    //    scrollToFragment();

    //    // Optionally, handle hash change if needed
    //    window.addEventListener('hashchange', scrollToFragment);

    //    // Cleanup event listener
    //    return () => {
    //       window.removeEventListener('hashchange', scrollToFragment);
    //    };
    // }, [section]);

    // console.log("section..", section);
    // console.log("data..", data);

    return (
        // <div className='flex'>
        //     <div className="content">
        //         <h1>{data.title}</h1>
        //         <div>
        //             <div id='section1'><h1>demo1</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur, voluptatibus! Accusamus non, quos itaque quibusdam veritatis modi, ipsam natus possimus doloremque optio omnis, quisquam deserunt eum similique! Quasi nesciunt ratione non.</div>
        //             <br />
        //             <div id="section2"><h1>demo2</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
        //             <br />
        //             <div id="section3"><h1>demo3</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
        //             <br />
        //             <div id="section4"><h1>demo4</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
        //             <br />
        //             <div id="section5"><h1>demo5</h1>Lorem ipsum dolor sit amet consectetur adipisicing elit. Non recusandae aliquam nihil magnam minus, cupiditate ea voluptatibus magni culpa. Magni quibusdam laborum quisquam eligendi. Pariatur est ex corporis amet dolor fuga eaque alias at sapiente sunt? Ex dolores blanditiis esse! Quae doloribus porro.</div>
        //         </div>
        //     </div>
        //     <div className="sidebar-new">
        //         <a href="#section1">Section 1</a>
        //         <a href="#section2">Section 2</a>
        //         <a href="#section3">Section 3</a>
        //         <a href="#section4">Section 4</a>
        //         <a href="#section5">Section 5</a>
        //     </div>
        // </div>

        //   <div className='flex'>
        <div>
            <div className='flex justify-content-between p-2 align-items-center'>
                <Button size="small" label="Previous" icon="pi pi-arrow-left" outlined onClick={handlePrevious} disabled={currentIndex === 0} />
                <h6 className='mb-0'>{routes[currentIndex]?.title}</h6>
                <Button size="small" label="Next" icon="pi pi-arrow-right" iconPos="right" outlined onClick={handleNext} disabled={currentIndex === routes.length - 1} />
            </div>
            <div className="content-new">
                <h1>{data.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div>
            <div className='flex justify-content-between p-2 align-items-center'>
                {currentIndex !== 0 && <Button severity="primary" size="small" label={routes[currentIndex - 1]?.title} icon="pi pi-arrow-left" text onClick={handlePrevious} disabled={currentIndex === 0} />}
                {currentIndex !== routes.length - 1 && <Button className="ml-auto" severity="primary" size="small" label={routes[currentIndex + 1]?.title} icon="pi pi-arrow-right" iconPos="right" text onClick={handleNext} disabled={currentIndex === routes.length - 1} />}
            </div>
        </div>
        //   </div>
    );
}

export default Content;