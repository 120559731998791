import React from 'react';
import Technologies from '../../Components/Technologies/Technologies';
import SEO from '../../Common/Components/SEO/SEO';
import { ReactTyped } from "react-typed";
import './Home.css';

const Home = () => {
    return (
        <>
            <SEO
                title="NextAspect Technologies - Innovative IT Solutions for Your Business"
                description="NextAspect Technologies provides cutting-edge IT solutions and services to empower businesses globally. Discover our expertise in technology innovation, consulting, and digital transformation."
                keywords="NextAspect Technologies, IT solutions, IT services, digital transformation, business consulting, technology innovation, global IT company"
                canonical="https://nextaspecttech.com/"
                robots="index, follow"
            />
            <div className="home-container">
                <video autoPlay loop muted className="background-video">
                    <source src={require('../../Assets/videos/Main.mp4')} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="centered-text text-white">
                    <p className='font-semiBold white-space-nowrap md:text-6xl sm:text-xl'>Premium IT Solutions Crafted <span className='sm:hidden'> <br /> </span>for Your Unique Needs&nbsp;</p>
                    <h2>
                        <ReactTyped
                            style={{ color: '#f5bb0c' }}
                            strings={['Web Development', 'Custom Development', 'Product Development', 'UX/UI Design', 'Software Testing & QA', 'IT Consultancy']}
                            typeSpeed={100}
                            backSpeed={50}
                            loop
                        />
                    </h2>
                    {/* <p>From Vision to Virtual Reality</p> */}
                </div>
            </div>
            <Technologies />
        </>
    );
};

export default Home;