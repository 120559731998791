import React, { useContext } from 'react';
import Carousel from 'react-multi-carousel';
import { AppContext } from '../../Contexts/AppContext';
import './Technologies.css';

// Responsive settings for the slider
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
    },
};

const Technologies = () => {
    const { technologiesContent } = useContext(AppContext);

    return (
        <section className="section-technology">
            <div className="container md:px-7">
                <div className="row">
                    <div className="col-lg-12 mb-4 mb-lg-0">
                        <div>
                            <div className="ot-heading text-center" data-aos="fade-right">
                                <span className="text-uppercase text-white">Our Technology Stack</span>
                                <h2 className="main-heading text-white">Empower Your Projects with Cutting-Edge Technologies</h2>
                                <p className='text-white'>Our team excels in a broad range of technologies to deliver powerful, scalable solutions for your business needs.</p>
                            </div>
                            {/* <ul className="style-none text-white">
                                <li><i className="fas fa-check"></i> Application Development</li>
                                <li><i className="fas fa-check"></i> BI Consulting and Implementation</li>
                                <li><i className="fas fa-check"></i> Machine and Deep Learning</li>
                                <li><i className="fas fa-check"></i> Data Quality Management</li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <Carousel
                            responsive={responsive}
                            infinite={true}
                            autoPlay={true}
                            autoPlaySpeed={3000}
                            swipeable={true}
                            arrows={false}
                            draggable={true}
                        >
                            {/* Tech items */}
                            {technologiesContent?.map((item, index) => (
                                <div className="tech-box text-center" key={index}>
                                    <div className="icon-main">
                                        <img src={item.ImageURL} alt={item.Title} />
                                    </div>
                                    <h5 className='white-space-nowrap'>{item.Title}</h5>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Technologies;