import React from 'react'
import { SidebarContextProvider } from '../../Components/SideBar/SidebarContext'
import Header from './Header'
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Content from './Content'
import Footer from './Footer';
import './HelpCenter.css';

const HelpCenter = () => {
    return (
        <SidebarContextProvider>
            <div style={{ height: "100%", width: "100%" }}>
                <Header />
                <Routes>
                    <Route path="/" element={<Navigate to="home" replace />} />
                    <Route path=":section" element={<Content />} />
                </Routes>
                <Footer />
                <Outlet />
            </div>
        </SidebarContextProvider>
    );
};

export default HelpCenter;