import React from 'react'
import { useProSidebar } from 'react-pro-sidebar';

const Header = () => {
    const { toggleSidebar, collapseSidebar, collapsed, broken, rtl } = useProSidebar();

    return (
        <div className='header-wrapper'>
            <div className='header__navbar'>
                <div className='header__navbar__left'>
                    {broken ? <i className="pi pi-bars px-3" icon="pi pi-bars" onClick={() => toggleSidebar()} /> :
                        <i className={`${collapsed ? "pi pi-bars" : "pi pi-times"} cursor-pointer px-3`} onClick={() => collapseSidebar()} />
                    }
                </div>
            </div>
        </div>
    );
};

export default Header;