import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import MagneticHover from '../../Components/MagneticHover/MagneticHover';
import Carousel from 'react-multi-carousel';
import { Dialog } from 'primereact/dialog';
import { AppContext } from '../../Contexts/AppContext';
import { toSlug, tryParseJSON } from '../../Common/commonFunction';
import SEO from '../../Common/Components/SEO/SEO';
import './ProductDetails.css';

const ProductDetails = () => {
    const { productDetailsContent } = useContext(AppContext);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [Features, setFeatures] = useState([]);
    const [HighlightsFeatures, setHighlightsFeatures] = useState([]);
    const [items, setItems] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const result = productDetailsContent.find(ele => toSlug(ele.ProductName) === id);
        if (result) {
            const parsedFeatures = tryParseJSON(result.Features);
            const parsedHighlightsFeatures = tryParseJSON(result.HighlightsFeatures);
            if (parsedFeatures) {
                setFeatures(parsedFeatures.sort((a, b) => a.OrderNo - b.OrderNo).filter(ele => ele.IsActive));
            } else {
                setFeatures([]);
            }
            if (parsedHighlightsFeatures) {
                setHighlightsFeatures(parsedHighlightsFeatures.sort((a, b) => a.OrderNo - b.OrderNo).filter(ele => ele.IsActive));
            } else {
                setHighlightsFeatures([]);
            }
            setItems(result);
        }
    }, [productDetailsContent, id]);

    // console.log("Features...", Features);
    // console.log("HighlightsFeatures...", HighlightsFeatures);
    // console.log("id...", productDetailsContent);
    // console.log("items...", items);


    // Function to open modal
    const openVideo = (e) => {
        e.preventDefault(); // Prevent the default link behavior
        setIsVideoOpen(true);
    };

    // Function to close modal
    const closeVideo = () => {
        setIsVideoOpen(false);
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    // const items = [
    //     'Item 1',
    //     'Item 2',
    //     'Item 3',
    //     'Item 4',
    //     'Item 5',
    // ];

    return (
        <>
            <SEO
                title={`${items?.ProductName} | NextAspect Technologies`}
                description={items?.Description}
                keywords={items?.Keywords}
                canonical={`https://nextaspecttech.com/products/${id}`}
                robots="index, follow"
            />
            {/* main screen */}
            <section className="product-banner-section pt-8 pb-5 md:h-screen flex align-items-center">
                <div className="container">
                    <div className="grid align-items-center is-mobile">
                        <div className="col-12 md:col-6">
                            <div className="title-content-inner">
                                <div className="section-title">
                                    <h1 className="">
                                        THE <span> ULTIMATE </span> TOOL TO <br /><span> MANAGE </span> YOUR SUPPORT<br />
                                        TICKETS, AND KEEP YOUR<br /> CLIENTS HAPPY. </h1>
                                    <p>
                                        Your clients are happier and more likely to trust you if you answer their
                                        queries fast. Smart HelpDesk makes it super easy for your clients to create
                                        tickets, and allows you to resolve them quickly and easily. </p>
                                </div>
                                <div className="btn-wrapper">
                                    <Link to="#" className="secondary-btn" tabIndex="0">Get it now</Link>
                                    {/* Try Smart HelpDesk Demo */}
                                    <Link to="#" className="try-now" tabIndex="0">Start Your Free Trial</Link>
                                    <Link to={`/help-center/${id}`} className="secondary-btn" tabIndex="0">Documentation</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 md:col-6">
                            <div className="right-side-wrapper">
                                <div className="img-wrapper">
                                    <MagneticHover>
                                        <img src={require('../../Assets/images/ProductDetails/helpdesk-banner.png')}
                                            alt="Smart HelpDesk The #1 Help Desk Ticketing Support System"
                                            width="100%" height="100% " loading="lazy" />
                                    </MagneticHover>

                                    {/* Video Play Icon Overlay */}
                                    {/* <div className="video-icon-overlay">
                                        <a href="https://www.youtube.com/watch?v=lfDZJqSrIuk" target="_blank" rel="noopener noreferrer">
                                            <i className="pi pi-play-circle play-icon"></i>
                                        </a>
                                    </div> */}

                                    <div className="image-rings">
                                        <div className="ring-0 ring"></div>
                                        <div className="ring-1 ring"></div>
                                        <div className="ring-2 ring"></div>
                                        <div className="ring-3 ring"></div>
                                    </div>

                                    {/* <div className="video-popup style-2 video-icon-overlay">
                                        <div className="btn-inner">
                                            <a className="btn-play" onClick={openVideo}>
                                                <i className="pi pi-play-circle play-icon"></i>
                                                <span className="circle-1"></span>
                                                <span className="circle-2"></span>
                                            </a>
                                        </div>
                                    </div> */}

                                    {/* Video Modal */}
                                    <Dialog
                                        visible={isVideoOpen}
                                        onHide={closeVideo}
                                        draggable={false}
                                        style={{ width: '60vw' }}
                                        header="Video Showcase"
                                    >
                                        <iframe
                                            width="100%"
                                            height="400"
                                            src="https://www.youtube.com/embed/lfDZJqSrIuk"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                        ></iframe>
                                    </Dialog>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* feature screen */}
            <section className="nk-integrate-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-9">
                            <div className="text-center">
                                <div>
                                    <h2>
                                        {/* Why <span>Smart HelpDesk?</span> Application Main Features */}
                                        Why <span>Smart HelpDesk?</span>
                                    </h2>
                                    <p className="text-xl">Smart HelpDesk application offers main features such as ticket
                                        management, canned responses, automation, reporting, and customization options
                                        to improve helpdesk efficiency and customer experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        {Features?.map((feature, index) => (
                            <div key={index} className="col-sm-6 col-md-4 col-lg-3" data-aos="fade-up" data-aos-delay={`${(index + 1) * 100}`}>
                                <div className="nk-feature-block text-center">
                                    <div className="nk-feature-block-content shadow-1 h-18rem">
                                        <div className="media media-xl media-middle text-bg-yellow-300 mb-4 application-icon">
                                            <img className="w-6" src={feature.ImgSrc} alt={feature.Title} />
                                            {feature.IsNew && <span className="badge badge-green blink_me">New</span>}
                                        </div>
                                        <h4 className="feature-title">{feature.Title}</h4>
                                        <p className="feature-description">{feature.Description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className="flex justify-content-center">
                            <Link to="/features" className="try-now" tabIndex="0" target='_blank'>Check the complete list of features</Link>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* HightLights screen */}
            <section className="section-space">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7">
                            <div className="nk-block-head-content text-center">
                                <div className="overline-title small mb-1 mb-md-2">our HightLights</div>
                                <h2 className="mt-0">Smart HelpDesk Highlights</h2>
                                <p className="nk-block-text lead ">Smart HelpDesk is a user-friendly ticketing system that
                                    streamlines communication and collaboration among agents, enabling efficient
                                    customer support.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {HighlightsFeatures?.map((feature, index) => (
                            <div key={index} className="col-md-6 col-lg-6 mb-4">
                                <div className='card p-0 h-100 rounded-2' data-aos="fade-right" data-aos-delay={`${(index + 1) * 100}`}>
                                    <div className="card-body">
                                        <div className="flex align-items-center">
                                            <div className="media media-lg media-middle media-circle text-bg-yellow-300 mb-4">
                                                <img className="w-6" src={feature.ImgSrc} alt={feature.Title} />
                                            </div>
                                            <h5 className="feature-title ml-3">{feature.Title}</h5>
                                        </div>
                                        <ul className="feature-description">
                                            <div dangerouslySetInnerHTML={{ __html: feature.Description }} />
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* integration screen */}
            <section className="integrations-container">
                {/* <h1 className="title-new">Integrations</h1> */}
                <h2 className="title-new">Limitless Solutions, One Platform</h2>
                <p className="description">
                    Contracts Center contains a feature called <strong>"Integrations"</strong> that export data to the SharePoint list. Leveraging this SharePoint list unlocks the full potential of Microsoft 365's Power Platform. Employ Power Automate for automated workflows and seamless communication with external platforms. Utilize Power BI for in-depth data visualization and analysis.
                    <br />
                </p>

                {/* <video autoPlay playsInline muted loop className='w-7'>
                    <source src={require('../../Assets/videos/Integrations.mp4')} type="video/mp4" />
                </video> */}
                <img className='w-11' src={require('../../Assets/images/ProductDetails/Integrations.png')} alt="" />
            </section>

            {/* support screen */}
            <div className="ftu">
                <div className='grid container'>
                    <div className="col-6 md:col-4 icon-cards-container">
                        <div className="icon-card">
                            <div className="icon">
                                <img src={require('../../Assets/images/ProductDetails/SmartDeskFeature/24-hours-support.png')} alt="" />
                            </div>
                        </div>
                        <div className="title">24/7 support</div>
                    </div>
                    <div className="col-6 md:col-4 icon-cards-container">
                        <div className="icon-card">
                            <div className="icon">
                                <img src={require('../../Assets/images/ProductDetails/SmartDeskFeature/customer-support.png')} alt="" />
                            </div>
                        </div>
                        <div className="title">Dedicated Customer Success Manager</div>
                    </div>
                    <div className="col-6 md:col-4 icon-cards-container">
                        <div className="icon-card">
                            <div className="icon">
                                <img src={require('../../Assets/images/ProductDetails/SmartDeskFeature/setting.png')} alt="" />
                            </div>
                        </div>
                        <div className="title">Installation</div>
                    </div>
                </div>
            </div>

            {/* template screen with feature */}
            <section className="product-detail-section py-6">
                <div className="container">
                    <div className="section-title mb-6">
                        <h2 className="sm:text-3xl lg:text-5xl">
                            Answer Your Clients’ Questions On Time. <span className='lg:text-6xl'> Take Your Customer Experience to The
                            </span><span className="text-white"> Next Level. </span> </h2>
                    </div>
                    <div className="grid align-items-center">
                        <div className="col-12 lg:col-7">
                            <div className="left-inner">
                                {/* <a target="_blank" href="https://demo.workdo.io/ticketgo-saas/login"
                                    className="secondary-btn" tabIndex="0">Request Live Demo  </a> */}
                                <div className="mt-3">
                                    <MagneticHover>
                                        <img src={require('../../Assets/images/ProductDetails/ticketgo-manage-business.png')}
                                            alt="ticketgo-saas" width="100%" height="100%" loading="lazy" />
                                    </MagneticHover>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 lg:col-5">
                            <div className="title-content-inner m-0">
                                <div className="section-title">
                                    <h3>
                                        Smart HelpDesk - The smartest way to manage your tickets
                                    </h3>
                                    <p className='prd-description'>
                                        Smart HelpDesk is the ONLY ticket management tool that makes it easy for you
                                        to manage support tickets smoothly. You and your team can access your
                                        clients’ tickets, respond to them, and resolve them - in a few clicks. </p>
                                </div>
                                <div className="users-rating">
                                    <p> Fully Verified Ratings <span><i className="fa-solid fa-star"></i><i
                                        className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i><i
                                            className="fa-solid fa-star"></i><i className="fa-solid fa-star"></i></span>
                                        Trusted by Hundreds of Happy Users. </p>

                                </div>
                                <div className="btn-wrapper mt-3">
                                    <span className="price"><span style={{ fontSize: '24px', color: '#fff' }}>Only</span>$99/ month</span>
                                    <a target="_blank"
                                        href="https://codecanyon.net/checkout/from_item/44318733?license=regular&amp;support=bundle_6month"
                                        className="secondary-btn" tabIndex="0">
                                        Request Live Demo
                                        {/* <i className='pi pi-arrow-right' /> */}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className="img-wrapper">
                <img src={require('../../Assets/images/ProductDetails/paymentgetway-langauage-banner.png')}
                    alt="dash" width="100%" height="100%" loading="lazy" />
            </div> */}

            {/* slider screen */}
            {/* <section className="listing-slider-section py-6">
                <div className="container">
                    <div className="section-title mb-6">
                        <h2 className="sm:text-3xl lg:text-4xl uppercase">
                            A BETTER WAY <span> TO </span> MANAGE <span> SUPPORT TICKETS AND </span><br /> IMPROVE
                            <span> CUSTOMER SATISFACTION </span>
                        </h2>
                    </div>
                    <div className="listing-slider flex-slider">
                        <Carousel
                            responsive={responsive}
                            autoPlay={true}
                            infinite={true}
                            showDots={true}
                            arrows={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {items.map((item, index) => (
                                <div key={index} className="listing-slides">
                                    <div className="grid align-items-center ">
                                        <div className="col-12 md:col-6">
                                            <div className="left-wrp">
                                                <MagneticHover>
                                                    <img src={require('../../Assets/images/ProductDetails/ticketgo-manage-business.png')}
                                                        alt="ticketgo-saas" width="100%" height="100%" loading="lazy" />
                                                </MagneticHover>
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6">
                                            <div className="right-wrp">
                                                <h3>
                                                    <span className="text-orange">
                                                        Eliminate The Chaos, And Run Your Business Smoothly. </span>
                                                </h3>
                                                <ul data-aos="fade-up">
                                                    <li>Keep track of all your support tickets and agents - with one central
                                                        dashboard. </li>
                                                    <li>Create ticket categories and gain access to unique ticket IDs,
                                                        subjects, statuses, and other important information.</li>
                                                    <li>Access all customer support tickets, and change their status status
                                                        to ‘in progress’, ‘on hold’, or ‘closed’.</li>
                                                    <li>Add multiple admins and agents, assign customer requests to them,
                                                        and resolve your customers’ tickets quickly - in place of you and
                                                        your team.</li>
                                                    <li>View a monthly support ticket chart, get a graphical presentation of
                                                        all ticket categories, and more!</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </section> */}

            {/* try new product screen */}
            {/* <div className='p-5'>
                <div className="container banner md:p-6">
                    <div className='grid'>
                        <div className="col-12 md:col-6 banner-content">
                            <h5>TRY NOW!</h5>
                            <h1>Check Out Our Asset Management System</h1>
                            <p>
                                Experience seamless features support application with our Smart HelpDesk. All essential
                                features available in this help desk software.
                            </p>
                            <button className="view-now-btn">View Now</button>
                        </div>
                        <div className="col-12 md:col-6 banner-image">
                            <img src={require('../../Assets/images/ProductDetails/banner-cover.png')} alt="Dashboard" />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* faq section */}
            <div className='faq-page py-7' style={{ background: '#0f0f0f' }}>
                <h1 className='text-center text-white pb-4'>Frequently Asked <span> Questions</span></h1>
                <div className="col-lg-8 mx-auto">
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div className="accordion" id="accordionExample1">
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            What is the trial period of the Smart HelpDesk?
                                        </button>
                                    </h5>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            The trial period for the Smart HelpDesk application typically spans 14 days, allowing users to experience the platform's full suite of features before committing to a paid plan.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                            Where app data is stored?
                                        </button>
                                    </h5>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                        <div className="accordion-body">
                                            App is 100% SharePoint-hosted - all data is stored securely on the customer's SharePoint environment.App's data is never leaving your SharePoint env, we don't have access to your data and we never see it.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProductDetails;