import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../Contexts/AppContext';
import { useParams } from 'react-router-dom';
import { tryParseJSON } from '../../Common/commonFunction';
import './ServiceDetails.css';

const ServiceDetails = () => {
    const { serviceDetailsContent } = useContext(AppContext);
    const [ServiceDescription, setServiceDescription] = useState([]);
    const [FAQContent, setFAQContent] = useState([]);
    const [Feature, setFeature] = useState([]);
    const [HowWeWork, setHowWeWork] = useState([]);
    const [items, setItems] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const result = serviceDetailsContent.find(ele => ele.ServiceId === id);
        if (result) {
            const parsedFeature = tryParseJSON(result.Feature);
            const parsedFAQContent = tryParseJSON(result.FAQContent);
            const parsedHowWeWork = tryParseJSON(result.HowWeWork);
            const parsedServiceDescription = tryParseJSON(result.ServiceDescription);

            if (parsedFeature) {
                setFeature(parsedFeature);
            } else {
                setFeature([]);
            }

            if (parsedFAQContent) {
                setFAQContent(parsedFAQContent);
            } else {
                setFAQContent([]);
            }

            if (parsedHowWeWork) {
                setHowWeWork(parsedHowWeWork);
            } else {
                setHowWeWork([]);
            }

            if (parsedServiceDescription) {
                setServiceDescription(parsedServiceDescription);
            } else {
                setServiceDescription([]);
            }

            setItems(result);
        }
    }, [serviceDetailsContent, id]);

    console.log("serviceDetailsContent...", serviceDetailsContent);
    console.log("Feature...", Feature);
    console.log("FAQContent...", FAQContent);
    console.log("HowWeWork...", HowWeWork);
    console.log("ServiceDescription...", ServiceDescription);
    console.log("items...", items);

    return (
        <div className='home-dark2'>
            <div className="service-details">
                <div className="about-services sec-mar">
                    <div className="container">
                        <div className="grid">
                            <div className="md:col-6 flex align-items-center" data-aos="fade-right">
                                <div className="about-content">
                                    <div className="section-title-5">
                                        <h2>{items?.ServiceTitle.split(' ')[0]} <br />{items?.ServiceTitle.split(' ')[1]}</h2>
                                    </div>
                                    <p>{ServiceDescription[0]?.Description}</p>
                                    <ul className="about-featue">
                                        {Feature && Feature.length > 0 && Feature.map((ele, index) => {
                                            return (
                                                <li key={index}>
                                                    <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0.376831 8.16821C-0.247095 8.54593 -0.0579659 9.49862 0.662688 9.60837C1.24211 9.69666 1.52052 10.3701 1.17304 10.8431C0.740845 11.4312 1.27942 12.2389 1.98713 12.0639C2.55609 11.9231 3.07065 12.4387 2.9302 13.0088C2.75556 13.718 3.56158 14.2577 4.14855 13.8246C4.62054 13.4764 5.29275 13.7554 5.38073 14.336C5.49024 15.0581 6.44099 15.2476 6.81798 14.6224C7.12107 14.1198 7.84864 14.1198 8.15171 14.6224C8.52867 15.2476 9.47943 15.0581 9.58896 14.336C9.67707 13.7554 10.3492 13.4764 10.8211 13.8246C11.4081 14.2577 12.2142 13.718 12.0395 13.0088C11.899 12.4387 12.4136 11.9231 12.9826 12.0639C13.6903 12.2389 14.2289 11.4312 13.7967 10.8431C13.4492 10.3701 13.7276 9.69653 14.307 9.60837C15.0276 9.49864 15.2168 8.54597 14.5929 8.16821C14.0912 7.86452 14.0912 7.13547 14.5929 6.83178C15.2168 6.45407 15.0277 5.50138 14.307 5.39162C13.7276 5.30334 13.4492 4.62989 13.7967 4.15695C14.2289 3.56879 13.6903 2.76112 12.9826 2.93613C12.4136 3.07687 11.8991 2.5613 12.0395 1.99115C12.2141 1.28199 11.4081 0.742345 10.8211 1.17541C10.3492 1.52356 9.67695 1.2446 9.58896 0.664029C9.47945 -0.0580599 8.5287 -0.247606 8.15171 0.377594C7.84863 0.880237 7.12106 0.880237 6.81798 0.377594C6.44103 -0.247596 5.49027 -0.0580833 5.38073 0.664029C5.29263 1.24462 4.62054 1.5236 4.14855 1.17541C3.56158 0.742345 2.75554 1.28201 2.9302 1.99115C3.07065 2.56126 2.55612 3.07686 1.98713 2.93613C1.2794 2.76113 0.740845 3.56879 1.17304 4.15695C1.52049 4.62989 1.24209 5.30346 0.662688 5.39162C-0.0579425 5.50136 -0.247105 6.45403 0.376831 6.83178C0.878459 7.13548 0.878459 7.86453 0.376831 8.16821Z">
                                                        </path>
                                                    </svg>
                                                    {ele.Title}
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="md:col-6" data-aos="fade-left">
                                <div className="service-img magnetic-item">
                                    <img className="img-fluid" src={require('../../Assets/images/serviceDetails/security_programming_hero.jpg')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="how-we-work-section sec-mar">
                    <div className="container">
                        <div className="grid mb-60" data-aos="fade-right">
                            <div className="col-12">
                                <div className="section-title">
                                    <h2>{items?.HowWeWorkLabel}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="work-item">
                        <div className="container-fluid">
                            <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-5 justify-content-center">
                                {HowWeWork && HowWeWork.length > 0 && HowWeWork.map((ele, index) => {
                                    const duration = 1000 + index * 500;
                                    return (
                                        <div key={index} className="col magnetic-item" data-aos="fade-up" data-aos-duration={duration}>
                                            <div className="single-work">
                                                <div className="work-icon">
                                                    <img src={ele.Icon} alt="" />
                                                </div>
                                                <div className="work-content">
                                                    <h3>{ele.Title}</h3>
                                                    <p>{ele.Description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })};
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-sort-driscription-area sec-mar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="driscription-img magnetic-item">
                                    <img className="img-fluid" src={require('../../Assets/images/serviceDetails/service-driscription-img-1.png')} alt="" />
                                </div>
                            </div>
                            <div className="driscription-content">
                                <div className="grid">
                                    <div className="md:col-6 wow animate fadeInUp" data-wow-delay="200ms"
                                        data-wow-duration="1500ms">
                                        <p>{ServiceDescription[1]?.Description}</p>
                                    </div>
                                    <div className="md:col-6 wow animate fadeInUp" data-wow-delay="300ms"
                                        data-wow-duration="1500ms">
                                        <p>{ServiceDescription[2]?.Description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-faq-area sec-mar">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-lg-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="faq-img magnetic-item">
                                    <img className="img-fluid" src={require('../../Assets/images/serviceDetails/service-faq-img-01.png')} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 d-flex align-items-center wow animate fadeInRight" data-wow-delay="300ms"
                                data-wow-duration="1500ms">
                                <div className="faq-content-area">
                                    <h2>{items?.FAQLabel}</h2>
                                    <div className="accordion" id="accordionExample">
                                        {FAQContent && FAQContent.length > 0 && FAQContent.map((ele, index) => {
                                            const collapseId = `collapse${index}`;
                                            const headingId = `heading${index}`;

                                            return (
                                                <div key={index} className="accordion-item">
                                                    <h6 className="accordion-header" id={headingId}>
                                                        <button className={`accordion-button ${index !== 0 && 'collapsed'}`} type="button" data-bs-toggle="collapse"
                                                            data-bs-target={`#${collapseId}`} aria-expanded="true"
                                                            aria-controls={collapseId}>
                                                            {ele?.Question}
                                                        </button>
                                                    </h6>
                                                    <div id={collapseId} className={`accordion-collapse ${index === 0 ? 'show' : 'collapse'}`}
                                                        aria-labelledby={headingId} data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">{ele?.Answer}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;