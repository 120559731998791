import { useContext, useEffect, useState } from "react";
import { Menu, Sidebar, MenuItem, SubMenu } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { useSidebarContext } from "./SidebarContext";
import { AppContext } from "../../Contexts/AppContext";
import { toSlug, tryParseJSON } from "../../Common/commonFunction";
import './MyProSidebar.css';

const MyProSidebar = () => {
    const { sidebarMenuItem, selected, setSelected } = useContext(AppContext);
    const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
    const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
    const [menu, setMenu] = useState([]);
    const location = useLocation();
    const id = location.pathname.split("/")[2];

    useEffect(() => {
        const result = sidebarMenuItem.find(ele => toSlug(ele.ProductName) === id);
        if (result) {
            const parsedSideBarMenu = tryParseJSON(result.MenuItem);
            if (parsedSideBarMenu) {
                setMenu(parsedSideBarMenu);
            } else {
                setMenu([]);
            }
        }
    }, [sidebarMenuItem, id]);

    return (
        <div className="side-bar-menu">
            <Sidebar
                defaultCollapsed={false}
                breakPoint="md"
                rtl={sidebarRTL}
                // backgroundColor="#0b2947"
                backgroundColor="#002433"
                image={sidebarImage}
                transitionDuration={1000}
            >
                <Menu>
                    <div
                        style={{
                            position: 'sticky',
                            top: 0,
                            height: '4rem',
                            background: '#002433',
                            zIndex: 10,
                        }}
                    >
                        {!collapsed && (
                            <div style={{ padding: '0.9rem' }}>
                                <Link to={"/"}><img src={require('../../Assets/images/Logo1.png')} alt="logo" /></Link>
                                {broken && <i className="pi pi-times text-white" onClick={broken ? () => toggleSidebar() : () => collapseSidebar()} />}
                            </div>
                        )}
                    </div>
                    {menu && menu?.map((menuSection, index) => (
                        <div key={index}>
                            {menuSection.header && !collapsed && <h5 className="menu-header my-0">{menuSection.header}</h5>}
                            {menuSection.items &&
                                menuSection.items.map((item, idx) => (
                                    <MenuItem
                                        key={idx}
                                        className="custom-menu-item"
                                        active={selected === item.to}
                                        style={{
                                            color: selected === item.title ? 'white' : '',
                                            backgroundColor: selected === item.to ? '#f5bb0c' : 'transparent',
                                        }}
                                        title={item.title}
                                        onClick={() => setSelected(item.to)}
                                        icon={item.icon ? <i className={item.icon} /> : null}
                                        component={<Link to={item.to} />}
                                    >
                                        <p className="mb-0">{item.title}</p>
                                    </MenuItem>
                                ))}
                            {menuSection.submenus &&
                                menuSection.submenus.map((submenu, subIdx) => (
                                    <SubMenu
                                        className="custom-menu-item"
                                        key={subIdx}
                                        label={submenu.title}
                                        title={submenu.title}
                                        icon={<i className={submenu.icon} aria-hidden="true" />}
                                    >
                                        {submenu.items.map((subItem, itemIdx) => (
                                            <MenuItem
                                                key={itemIdx}
                                                className="custom-menu-item"
                                                active={selected === subItem.to}
                                                style={{
                                                    color: selected === subItem.title ? 'white' : '',
                                                    backgroundColor: selected === subItem.to ? '#f5bb0c' : 'transparent',
                                                }}
                                                title={subItem.title}
                                                onClick={() => setSelected(subItem.to)}
                                                icon={subItem.icon ? <i className={subItem.icon} /> : null}
                                                component={<Link to={subItem.to} />}
                                            >
                                                <p className="mb-0">{subItem.title}</p>
                                            </MenuItem>
                                        ))}
                                    </SubMenu>
                                ))}
                        </div>
                    ))}
                </Menu>
            </Sidebar>
        </div>
    );
};

export default MyProSidebar;